import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LX from "../../assets/LX.png";
import { makeStyles, Grow } from "@material-ui/core";
import { getCsmToken } from "./api"

import MainPage from "../../MainPage";

const apiUrl = "https://api-lab.locatorx.com/lx-atlas/api/";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function App() {
  const classes = useStyles();
  const location = window.location.href
  const [csm,setCSM] = useState('')
  const [isInitializing, setIsInitializing] = useState(true);
  const NoAuthRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => <Component {...props} apiUrl={apiUrl} csm={csm} />}
    />
  );

  // We grab the url and accquire the csm token before loading the page
  useEffect(()=>{
    getCsmToken(apiUrl,location).then((token)=>{
      setCSM(token)
      setIsInitializing(false)
    })
  })

  return (
    <Router>
      {isInitializing ? (
        <div className={classes.container}>
          <Grow
            in={isInitializing}
            style={{ transformOrigin: "0 0 0 0" }}
            timeout={1000}
          >
            <img
              src={LX}
              width="300px"
              height="300px"
              alt="LocatorX launch logo"
            />
          </Grow>
        </div>
      ) : (
        <div>
          <NoAuthRoute exact path="/" component={MainPage} />
        </div>
      )}
    </Router>
  );
}
