import React from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { searchAssets } from "../api";
import ReactSelectCheckboxes from "../../../components/Selects/ReactSelectCheckboxes";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../../../components/Loading/Loading";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncReactSelectCheckboxes from "../../../components/Selects/AsyncReactSelectCheckbox";

import "./styles.scss";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: "#6D6E70",
    color: "white",
  },
  datePicker:{
    '&:after':  {
      boxSizing:'border-box !important'
    },
    '&:before':  {
      boxSizing:'border-box !important'
    }
  },
  filterButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    marginLeft: "1rem !important",
    "&:hover": {
      backgroundColor: "#5884F5",
      color: "#fff",
    },
  },
  filter: {
    padding: ".5rem 0 !important",
  },
  filterOption: {
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: ".5rem",
    marginTop: "1vh",
    fontWeight: 700,
    fontFamily: "Raleway, sans-serif",
    fontSize: "0.875rem",
  },
  filterOptionLabel: {
    fontFamily: "Raleway, sans-serif",
    fontWeight: 700,
    display: 'inline-block',
    margin: '.5rem 0',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    fontSize: '0.875rem'
  },
  field: {
    paddingTop: ".5rem",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterMenu(props: any) {
  const { csm, nodeAndRadioOptions = {}, parentFilters, setParentFilters, setState } = props;
  const [filterState, setFilterState] = React.useState<any>({...parentFilters});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { node = {}, radio = {}} = nodeAndRadioOptions
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFilterState({ ...parentFilters });
    setOpen(false);
  };

  const asyncAssetIdSearchLoadOptions = async (
    inputValue = "",
    loadedOptions: any
  ) => {
    const { apiUrl, setConfirm } = props;

    const results = await searchAssets(
      { apiUrl, csm },
      inputValue,
      300,
      loadedOptions.length > 0 ? loadedOptions.length : 0
    ).then((results) => {
      if (results.error) {
        return setConfirm({
          modalShow: true,
          text: results.error,
          error: true,
        });
      }
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset: any) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                  batchId: asset.batchId,
                  assetType: asset.assetType,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  return (
    <>
      <span onClick={handleClickOpen} style={{ cursor: "pointer" }}>
        <div className="exportIcon">
          {`Filters`}
          <i className="fas fa-filter ml-2"></i>
        </div>
      </span>
      <Dialog
        aria-labelledby="filters"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="filters" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container justify="space-between" spacing={1}>
            {/* Asset Tag */}
            <Grid className={classes.filter} item style={{paddingLeft:'0 !important'}} xs={12}>
              <div className={classes.filterOptionLabel}>
                <label>Asset Tag</label>
              </div>
              <AsyncReactSelectCheckboxes
                loadOptions={asyncAssetIdSearchLoadOptions}
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    assetIds: e,
                  }));
                }}
                value={filterState.assetIds}
              />
            </Grid>

            {/* Nodes */}
            <Grid className={classes.filter} item xs={12}>
              <div className={classes.filterOptionLabel}>
                <label>Node</label>
              </div>
              <ReactSelectCheckboxes
                className={classes.field}
                options={
                  nodeAndRadioOptions
                    ? Object.keys(node).map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      })
                    : []
                }
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    node: e,
                  }));
                }}
                value={filterState.node}
              />
            </Grid>

            {/* Radio */}
            <Grid className={classes.filter} item xs={12}>
              <div className={classes.filterOptionLabel}>
                <label>Radio</label>
              </div>
              <ReactSelectCheckboxes
                options={
                  nodeAndRadioOptions
                    ? Object.keys(radio).map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      })
                    : []
                }
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    radio: e,
                  }));
                }}
                value={filterState.radio}
              />
            </Grid>

            {/* Start Date */}
            <Grid item className={classes.datePicker} style={{paddingRight:'1rem'}} xs={6}>
              <div className={classes.filterOptionLabel}>
                <label>Select Start Date</label>
              </div>
              <DatePicker
                className="custom-select no-select-arrows"
                dateFormat="yyyy/MM/dd"
                placeholderText="Start date"
                onChange={(date: any) => {
                  if (filterState.endDate && date > filterState.endDate) {
                    alert("Can't set start date later than end date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    startDate: date,
                  }));
                }}
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
                popperPlacement="bottom-start"
                selected={filterState.startDate}
              />
            </Grid>

            {/* End Date */}
            <Grid item className={classes.datePicker} xs={6}>
              <div className={classes.filterOptionLabel}>
                <label>Select End Date</label>
              </div>
              <DatePicker
                className="custom-select no-select-arrows"
                dateFormat="yyyy/MM/dd"
                placeholderText="End date"
                onChange={(date: any) => {
                  if (filterState.startDate && date < filterState.startDate) {
                    alert("Can't set end date earlier than start date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    endDate: date,
                  }));
                }}
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
                popperPlacement="bottom-start"
                selected={filterState.endDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <Loading color="#5884F5" opaque /> : ""}

          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilterState({
                assetIds: [],
                count: 0,
                endDate: null,
                limit: 25,
                node: null,
                page: 0,
                radio: null,
                sorted: [{ id: "time_of_report", desc: true }],
                start: 0,
                startDate: null,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            className={classes.button}
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.filterButton} ${classes.button}`}
            onClick={() => {
              setState((prevState: any) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  start: "0",
                },
              }));
              setParentFilters(filterState);
              setLoading(false);
              setOpen(false);
            }}
            variant="contained"
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
