import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ControlledTable from "../../components/Tables/ControlledTable/ControlledTable";
import FilterMenu from "./FilterMenu/FilterMenu";
import { retrieveTelemetry, retrieveZoneRadioIds } from "./api";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

export default function Table(props: any) {
  const { apiUrl, csm, locationRef, parentFilters, setParentFilters } = props;
  const prevFiltersRef = React.useRef({});
  const logTableColumns = [
    {
      Header: "Time of Report",
      accessor: (row: any) => row.timeOfReport || "DNP",
      id: "timeOfReport",
      Cell: (data: any) => {
        const { timeOfReport } = data.row.original;
        const { hour, minute, second, monthValue, year, dayOfMonth } = timeOfReport;

        return <div>{` ${hour}:${minute}:${second} ${monthValue}/${dayOfMonth}/${year}` || "DNP"}</div>;
      },
    },
    {
      Header: "Verb",
      accessor: (row: any) => row.verb || "DNP",
      id: "verb",
      Cell: (data: any) => {
        const { verb } = data.row.original;

        return <div>{verb || "DNP"}</div>;
      },
    },
    {
      Header: "Node",
      accessor: (row: any) => row.node || "DNP",
      id: "node",
      Cell: (data: any) => {
        const { node } = data.row.original;

        return <div>{node || "DNP"}</div>;
      },
    },
    {
      Header: "Radio ID",
      accessor: (row: any) => row.radio || "DNP",
      id: "radio",
      Cell: (data: any) => {
        const { radio } = data.row.original;

        return <div>{radio || "DNP"}</div>;
      },
    },
    {
      Header: "Asset ID",
      accessor: (row: any) => row.assetId || "DNP",
      id: "assetId",
      Cell: (data: any) => {
        const { assetId } = data.row.original;

        return <div>{assetId || "DNP"}</div>;
      },
    },
    {
      Header: "RSSI",
      accessor: (row: any) => row.rssi || "DNP",
      id: "rssi",
      Cell: (data: any) => {
        const { rssi } = data.row.original;

        return <div>{rssi || "DNP"}</div>;
      },
    },
    {
      Header: "Battery %",
      accessor: (row: any) => row.batteryPercent || "DNP",
      id: "batteryPercent",
      Cell: (data: any) => {
        const { batteryPercent } = data.row.original;

        return <div>{batteryPercent || "DNP"}</div>;
      },
    },
    {
      Header: "Type",
      accessor: (row: any) => row.reportType || "DNP",
      id: "reportType",
      Cell: (data: any) => {
        const { reportType } = data.row.original;

        return <div>{reportType || "DNP"}</div>;
      },
    },
    {
      Header: "Channel",
      accessor: (row: any) => row.channel || "DNP",
      id: "channel",
      Cell: (data: any) => {
        const { channel } = data.row.original;

        return <div>{channel || "DNP"}</div>;
      },
    },
    {
      Header: "TX Power",
      accessor: (row: any) => row.transPower || "DNP",
      id: "transPower",
      Cell: (data: any) => {
        const { transPower } = data.row.original;

        return <div>{transPower || "DNP"}</div>;
      },
    },
    {
      Header: "Tag Ver",
      accessor: (row: any) => row.tagVersion || "DNP",
      id: "tagVersion",
      Cell: (data: any) => {
        const { tagVersion } = data.row.original;

        return <div>{tagVersion || "DNP"}</div>;
      },
    },
  ];
  const [defaultColumnOrder, setDefaultColumnOrder] = useState([
    "timeOfReport",
    "verb",
    "node",
    "radio",
    "assetId",
    "rssi",
    "batteryPercent",
    "reportType",
    "channel",
    "transPower",
    "tagVersion",
  ]);
  const [state, setState] = useState<any>({
    filters: {
      count: 0,
      limit: 25,
      page: 0,
      sorted: [{ id: "time_of_report", desc: true }],
      start: 0,
    },
    page: 0,
  });
  const [nodeAndRadioOptions, setNodeAndRadioOptions] = useState({});

  const classes = useStyles();

  useEffect(() => {
    const setRowsAndCount = () => {
      retrieveTelemetry(
        { ...props },
        { ...state.filters, ...parentFilters }
      ).then((response) => {
        if (response.success) {
          if (!_.isEqual(state?.filters, prevFiltersRef.current)) {
            prevFiltersRef.current = state?.filters;
          }

          setState((prevState: any) => ({
            ...prevState,
            filters: { ...prevState.filters, count: response.count },
            logs: response.logs,
            page: Math.floor(prevState.filters.start / prevState.filters.limit),
          }));
        }
      });
    };

    if (
      // !_.isEqual(state?.filters, prevFiltersRef.current)
      prevFiltersRef.current === {}
    ) {
      prevFiltersRef.current = state?.filters;
      // Initial Call to populate the table
      setRowsAndCount();
    }

    const interval = setInterval(() => {
      setRowsAndCount();
    }, 5000);

    return () => clearInterval(interval);
  }, [locationRef, parentFilters, prevFiltersRef, props, state.filters]);

  useEffect(() => {
    const node: any = {};
    const radio: any = {};
    retrieveZoneRadioIds({ ...props }).then((response) => {
      response.radios.forEach((item: any) => {
        Object.keys(item).forEach((element) => {
          if (element === "node" && !node[item.node]) {
            node[item.node] = { label: item.node, value: item.node };
          }

          if (element === "radio" && !radio[item.radio]) {
            radio[item.radio] = { label: item.radio, value: item.radio };
          }
          return null;
        });
      });
    });

    setNodeAndRadioOptions({ node, radio });
  }, [props]);

  return (
    <ControlledTable
      columns={logTableColumns}
      controlledCount={state.filters?.count || 0}
      controlledPageCount={Math.ceil(
        parseInt(state.filters?.count) / parseInt(state.filters?.limit)
      )}
      clearSelected={() => {
        return null;
      }}
      controlledPageIndex={state.page}
      controlledPageSize={state.filters?.limit}
      data={state.logs || []}
      defaultColumnOrder={defaultColumnOrder}
      defaultSort={state.filters?.sorted || [{ id: "timeOfLog" }]}
      globalFilterLabel={"assets"}
      isSelect={false}
      onColumnDrop={(colOrder: any) => {
        setDefaultColumnOrder(colOrder);
      }}
      onSelect={() => {
        return null;
      }}
      rowOptions={[10, 25, 50, 100, 250, 500, 1000]}
      saveFilterSettings={() => {
        // console.log("hit");
      }}
      selectAll={() => {
        return null;
      }}
      setState={setState}
      state={state}
      stickyHeader
      toolbarChildren={
        <Grid className={classes.barChildrenContainer} container>
          <Grid className={classes.barChildren} item xs={3} xl={2}>
            {state && state.filters ? (
              <FilterMenu
                apiUrl={apiUrl}
                csm={csm}
                nodeAndRadioOptions={nodeAndRadioOptions}
                parentFilters={parentFilters}
                setParentFilters={setParentFilters}
                setState={setState}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      }
    />
  );
}
