import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "./Table";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function Logs(props) {
  const classes = useStyles();
  const locationRef = React.useRef(props.history.location.pathname);
  const [parentFilters,setParentFilters] = useState({
    assetIds:[],
    endDate:null,
    node: null,
    radio:null,
    startDate: null,
  })

  return (
    <div className={classes.root}>
      <Table
        {...props}
        parentFilters={parentFilters}
        setParentFilters={setParentFilters}
        locationRef={locationRef}
      />
    </div>
  );
}
export default Logs;
