import moment from "moment";

const escapeCharacters = (input) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export const retrieveTelemetry = async (props, filters) => {
  const { apiUrl } = props;
  let { assetIds, endDate, limit, node, radio, sorted, start, startDate } = filters;

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  const formatedEndDate =
    moment(endDate).isValid() && endDate
      ? moment(endDate).endOf("day").utc().format()
      : null;
  const formatedStartDate =
    moment(startDate).isValid() && startDate
      ? moment(startDate).startOf("day").utc().format()
      : null;

    let assetIdString = "";
    if (assetIds && assetIds.length) {
      let assetIdSet = assetIds.map((e) => {
        return `asset_id:${escapeCharacters(e.value)}`;
      });
      assetIdString = assetIdSet.join(" OR ");
    }

  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj = {
            id: "",
            order: sorted[0].desc ? "desc" : "asc",
          };
          switch (sorted[0].id) {
            case "time_of_report":
              obj.id = "time_of_report";
              break;
            default:
              obj.id = "time_of_report";
              break;
          }
          return obj;
        })()
      : "";

  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id} `;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order} `;
          }
        })()
      : "";

  let nodesString = null;
  if (node && node.length) {
    let nodeSet = node.map((e) => {
      return `node:${escapeCharacters(e.value)}`;
    });
    nodesString = nodeSet.join(" OR ");
  }

  let radiosString = null;
  if (radio && radio.length) {
    let radiosSet = radio.map((e) => {
      return `radio:${escapeCharacters(e.value)}`;
    });
    radiosString = radiosSet.join(" OR ");
  }

  const body = {
    csmToken: props.csm,
    limit: limit ? limit : 25,
    solrQuery: {
      fq: [
        assetIdString ? assetIdString : "",
        nodesString ? `${nodesString}` : "",
        startDate || endDate
        ? `time_of_report:[${formatedStartDate || `*`} TO ${formatedEndDate || `*`}]`
        : ``,
        radiosString ? `${radiosString}` : "",
      ],
      sort: `${sortedString}`,
      start: start ? start : 0,
    },
  };

  const results = await fetch(`${apiUrl}telemetry/csm/onsite/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error:
          "Failed to fetch asset data, please contact system administrator.",
      };
    });

  return results;
};

export const retrieveZoneRadioIds = async (props) => {
  const { apiUrl, csm } = props;
  const body = {
    csmToken: csm,
  };

  const results = await fetch(`${apiUrl}radios/csm/onsite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    });

  return results;
};

export const searchAssets = async (
  props,
  input,
  limit = 50,
  start = 0
) => {
  const { apiUrl } = props;
  // "-{!tuple}device.platform:*" is a negated filter that filters out assets that are devices. The minus sign makes it a negative query.
  const payload = {
    solrQuery: {
      sort: `tag asc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.csm,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

