export const getCsmToken = async (apiUrl, url) => {
  const body = {
    csmToken: "9ebd363b-3374-4924-9789-456b42a8eec3",
    url:
      url === "http://localhost:3001/"
        ? "https://geresearch.locatorx.com "
        : url,
  };

  const results = await fetch(`${apiUrl}data/csm/getCredential`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => response.csmToken)
    .catch((error) => {
      console.log(`There was an issue: ${error}`);
    });
  return results;
};
